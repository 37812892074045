// import cloneDeep from 'lodash/cloneDeep';

export class KanbanProgramacaoModel {
  constructor({
    colunas
  }) {
    this.colunas = colunas;
  }

  get request() {
    const dados = { cards: {} };
  
    for (const { tipoColuna, cartoes, ...dadosColuna } of this.colunas) {
      dados.cards[tipoColuna] = {
        cartoes: [...cartoes],
        dados: { ...dadosColuna, tipoColuna },
      };
    }
  
    return dados;
  }
}
