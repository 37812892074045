<template>
  <div class="d-flex justify-center">
    <span class="message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'Mensagem',
  props: { message: { type: String, default: '' } },
};
</script>

<style scoped>
div {
  margin-top: 200px;
  height: 100%;
}
.message {
  color: gray;
  opacity: 0.8;
  font-size: 1.2rem;
  margin-bottom: 5rem;
}
</style>
