var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Cabecalho',{attrs:{"mostrar-configuracao":false,"loading":_vm.loading},on:{"atualizaSetor":_vm.atualizaSetor}}),_c('div',{staticClass:"board"},[_c('div',{staticClass:"coluna-left kanban-header"},[_c('div',{staticClass:"coluna-left-wrapper"},[_c('span',{staticClass:"coluna-nome"},[_vm._v(" "+_vm._s(_vm.$t('modulos.kanban_programacao_manual.colunas.data'))+" ")]),_c('span',{staticClass:"coluna-nome"},[_vm._v(" "+_vm._s(_vm.$t( 'modulos.kanban_programacao_manual.colunas.num_horas_disponiveis' ))+" ")]),_c('span',{staticClass:"coluna-nome"},[_vm._v(" "+_vm._s(_vm.$t('modulos.kanban_programacao_manual.colunas.num_horas_alocadas'))+" ")])])]),_vm._l((_vm.dados.cards),function(item,coluna){return _c('div',{key:coluna,staticClass:"lane"},[_c('div',{staticClass:"kanban-header colunas"},[_c('span',{staticClass:"coluna-nome pointer",on:{"click":function($event){return _vm.redireciona(item.dados.tipoColuna)}}},[_vm._v(_vm._s(_vm.$t(`modulos.kanban_programacao_manual.colunas.${coluna}`))+" ( "+_vm._s(item.dados.quantidadeCartoes)+" ) ")])]),_c('div',{staticClass:"detalhe-container"},[_c('DetalheCartaoItem',{staticStyle:{"min-height":"32px"},attrs:{"label":_vm.mostrarLabelResponsivo
              ? `Data: ${item.dados.data}`
              : _vm.dataFormatada(item.dados.data),"is-strong":false,"font-size":'1rem',"background":"#fff","padding":"4px 8px"}}),(coluna == 'AguardandoProgramacao')?_c('DetalheCartaoItem',{staticStyle:{"min-height":"32px"},attrs:{"is-strong":false,"font-size":'1rem',"background":"#fff","padding":"4px 8px"}}):_c('DetalheCartaoItem',{staticStyle:{"min-height":"32px"},attrs:{"label":_vm.mostrarLabelResponsivo
              ? `Horas Alocadas: ${item.dados.totalHorasDisponiveis}`
              : _vm.formatarHorasParaString(item.dados.totalHorasDisponiveis) ||
                '',"is-strong":false,"font-size":'1rem',"background":"#fff","padding":"4px 8px"}}),_c('DetalheCartaoItem',{staticStyle:{"min-height":"32px"},attrs:{"label":_vm.mostrarLabelResponsivo
              ? `Horas Disponíveis: ${item.dados.totalHorasAlocadas}`
              : _vm.formatarHorasParaString(item.dados.totalHorasAlocadas) || '',"is-strong":false,"font-size":'1rem',"background":"#fff","padding":"4px 8px"}})],1),_c('Container',{attrs:{"drop-placeholder":{ className: 'placeholder' },"group-name":`${item.dados.coluna}`,"get-child-payload":_vm.obterChildPayload},on:{"drag-start":function($event){return _vm.manipularInicioArrastarCard(coluna, $event)},"drop":function($event){return _vm.manipularSoltarCard(coluna, $event)}}},_vm._l((item.cartoes),function(cartao,index){return _c('Draggable',{key:index},[_c('Cartao',{attrs:{"cartao":cartao,"redireciona":false,"margin":"8px","programacao-manual":""}})],1)}),1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }