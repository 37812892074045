<template>
  <div
    class="card"
    :style="{ backgroundColor: background, color: corFonte, margin: margin, fontSize:fontSize }"
    @click="redirecionaUsuario"
  >
    <detalhe-cartao-item :label="cartao.apelidoContratante" :font-size="fontSize"/>

    <detalhe-cartao-item
      :label="cartao.codigo"
      :font-size="fontSize"
      is-strong
    />
    <detalhe-cartao-item :label="cartao.solicitacao" :font-size="fontSize"/>

    <detalhe-cartao-item
      v-if="cartao.roteiro"
      :label="`Roteiro: ${cartao.roteiro}`"
      :font-size="fontSize"
    />

    <detalhe-cartao-item :label="cartao.nomeTransportadora" :font-size="fontSize"/>

    <detalhe-cartao-item
      v-if="cartao.prazo"
      :label="`Prazo: ${formataData(cartao.prazo)}`"
      :font-size="fontSize"
    />

    <detalhe-cartao-item
      v-if="cartao.noSetor"
      :font-size="fontSize"
      :label="`No Setor: ${cartao.noSetor}`"
    />

    <detalhe-cartao-item
      v-if="cartao.esforcoHoras || programacaoManual"
      :label="`Horas: ${formatarHorasParaString(cartao.esforcoHoras)}`"
      :font-size="fontSize"
    />

    <detalhe-cartao-item
      v-if="cartao.prazoEntregaPecas"
      :label="`${cartao.prazoEntregaPecas}`"
      :font-size="fontSize"
    />

    <div v-if="cartao.pecas">
      <detalhe-cartao-item
        v-for="({ quantidade, codigo }, index) in cartao.pecas"
        :key="codigo"
        :label="`${quantidade} ${codigo}`"
        :has-comma="index < cartao.pecas.length - 1"
        :font-size="fontSize"
      />
    </div>

    <div v-if="cartao.instrumentosOutrosSetores">
      <detalhe-cartao-item
        v-for="(
          { quantidade, siglaSetor }, index
        ) in cartao.instrumentosOutrosSetores"
        :key="index"
        :label="`${quantidade} ${siglaSetor}`"
        :has-comma="index < cartao.instrumentosOutrosSetores.length - 1"
        :font-size="fontSize"
      />
    </div>

    <detalhe-cartao-item
      v-if="cartao.suplemento"
      label="SUPLEMENTO"
      :font-size="fontSize"
    />
  </div>
</template>

<script>
import moment from 'moment';
import DetalheCartaoItem from './DetalheCartaoItem.vue';

export default {
  name: 'Cartao',
  components: { DetalheCartaoItem },
  props: {
    cartao: { type: Object, default: () => {} },
    background: { type: String, default: '' },
    corFonte: { type: String, default: '' },
    margin: { type: String, default: '' },
    fontSize: { type: String, default: '1rem' },

    setor: { type: [Object, String], default: '' },
    setorId: { type: String, default: '' },

    redireciona: { type: Boolean, default: true },

    programacaoManual: { type: Boolean, default: false },
  },

  methods: {
    redirecionaUsuario() {
      if (!this.redireciona) return;

      const situacao = 'AguardandoRecebimento';
      if (this.cartao.situacao == situacao) {
        this.$router.push({
          name: 'envio-material',
          query: { enviomaterial: this.formataCodigo(this.cartao.codigo) },
        });
        return;
      }
      this.$router.push({
        name: 'operacao',
        query: {
          setor: this.setor,
          ordemServico: this.formataCodigo(this.cartao.codigo),
          setorId: this.setorId,
        },
      });
    },
    formatarHorasParaString(horas) {
      if(horas == 0) return '0 horas';
      const totalMinutos = Math.round(horas * 60);
      const horasInteiras = Math.floor(totalMinutos / 60);
      const minutos = totalMinutos % 60;

      const partes = [];
      if (horasInteiras > 0)
        partes.push(`${horasInteiras} hora${horasInteiras > 1 ? 's' : ''}`);
      if (minutos > 0)
        partes.push(`${minutos} minuto${minutos > 1 ? 's' : ''}`);

      return partes.join(' e ') || '0 minutos';
    },

    formataCodigo(codigo) {
      return codigo.replace(/^EM\s+/i, '').trim();
    },
    formataData(data) {
      if (!data) return '';
      return moment(data, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
}
</style>
