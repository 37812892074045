<template>
  <span
    :style="{
      fontSize: fontSize,
      fontWeight: isStrong ? 'bold' : 'normal',
      backgroundColor: background,
      padding: padding,
    }"
  >
    {{ label }}
    <span v-if="hasComma">,</span>
  </span>
</template>

<script>
export default {
  props: {
    label: [Number,String],
    fontSize: {
      type: String,
      default: '1rem',
    },
    isStrong: { type: Boolean, default: false },
    background: { type: String, default: '' },
    padding: { type: String, default: '' },
    hasComma: { type: Boolean, default: false },
  },
};
</script>
