<template>
  <div>
    <Cabecalho
      :mostrar-configuracao="false"
      :loading="loading"
      @atualizaSetor="atualizaSetor"
    />
    <div class="board">
      <div class="coluna-left kanban-header">
        <div class="coluna-left-wrapper">
          <span class="coluna-nome">
            {{ $t('modulos.kanban_programacao_manual.colunas.data') }}
          </span>
          <span class="coluna-nome">
            {{
              $t(
                'modulos.kanban_programacao_manual.colunas.num_horas_disponiveis'
              )
            }}
          </span>
          <span class="coluna-nome">
            {{
              $t('modulos.kanban_programacao_manual.colunas.num_horas_alocadas')
            }}
          </span>
        </div>
      </div>
      <div v-for="(item, coluna) in dados.cards" :key="coluna" class="lane">
        <div class="kanban-header colunas">
          <span
            class="coluna-nome pointer"
            @click="redireciona(item.dados.tipoColuna)"
            >{{ $t(`modulos.kanban_programacao_manual.colunas.${coluna}`) }} (
            {{ item.dados.quantidadeCartoes }} )
          </span>
        </div>
        <div class="detalhe-container">
          <DetalheCartaoItem
            :label="
              mostrarLabelResponsivo
                ? `Data: ${item.dados.data}`
                : dataFormatada(item.dados.data)
            "
            :is-strong="false"
            :font-size="'1rem'"
            background="#fff"
            padding="4px 8px"
            style="min-height: 32px"
          />

          <DetalheCartaoItem
            v-if="coluna == 'AguardandoProgramacao'"
            :is-strong="false"
            :font-size="'1rem'"
            background="#fff"
            padding="4px 8px"
            style="min-height: 32px"
          />
          <DetalheCartaoItem
            v-else
            :label="
              mostrarLabelResponsivo
                ? `Horas Alocadas: ${item.dados.totalHorasDisponiveis}`
                : formatarHorasParaString(item.dados.totalHorasDisponiveis) ||
                  ''
            "
            :is-strong="false"
            :font-size="'1rem'"
            background="#fff"
            padding="4px 8px"
            style="min-height: 32px"
          />
          <DetalheCartaoItem
            :label="
              mostrarLabelResponsivo
                ? `Horas Disponíveis: ${item.dados.totalHorasAlocadas}`
                : formatarHorasParaString(item.dados.totalHorasAlocadas) || ''
            "
            :is-strong="false"
            :font-size="'1rem'"
            background="#fff"
            padding="4px 8px"
            style="min-height: 32px"
          />
        </div>
        <Container
          :drop-placeholder="{ className: 'placeholder' }"
          :group-name="`${item.dados.coluna}`"
          :get-child-payload="obterChildPayload"
          @drag-start="manipularInicioArrastarCard(coluna, $event)"
          @drop="manipularSoltarCard(coluna, $event)"
        >
          <Draggable v-for="(cartao, index) in item.cartoes" :key="index">
            <Cartao
              :cartao="cartao"
              :redireciona="false"
              margin="8px"
              programacao-manual
            />
          </Draggable>
        </Container>
      </div>
    </div>

    <!-- <mensagem
      v-else
      :message="$t('modulos.kanban_programacao_manual.mensagem_nao_ha_dados')"
    /> -->
  </div>
</template>

<script>
import { Cartao, DetalheCartaoItem } from '@/components/kanban/cards';
import Cabecalho from './components/Cabecalho.vue';

import { KanbanProgramacaoModel } from '@/common/models/cadastros/KanbanProgramacaoModel';
import KanbanProgramaManualmenteService from '@/common/services/cadastros/KanbanProgramaManualmenteService';
import helpers from '@/common/utils/helpers';
import { Container, Draggable } from 'vue-smooth-dnd';

export default {
  components: {
    Cabecalho,
    Cartao,
    DetalheCartaoItem,
    Container,
    Draggable,
  },
  data() {
    return {
      mostrarLabelResponsivo: window.innerWidth <= 700,
      loading: false,
      setor: '',
      setorId: null,
      colunas: [],
      dados: {
        cards: {},
      },
      draggingCard: {
        lane: '', //de onde o card saiu
        index: -1,
        cardData: {},
      },
    };
  },
  computed: {
    temDados() {
      return (
        Object.values(this.dados.cards).flatMap((item) => item.cartoes).length >
        0
      );
    },
  },

  watch: {
    setorId() {
      this.listarRegistros();
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Programacao', 'Listar');
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.kanban_programacao_manual.titulos.listagem')
    );
    window.addEventListener('resize', this.atualizarMostrarLabel);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.atualizarMostrarLabel);
  },
  methods: {
    formatarHorasParaString(horas) {
      if (horas == 0) return '0 horas';
      const totalMinutos = Math.round(horas * 60);
      const horasInteiras = Math.floor(totalMinutos / 60);
      const minutos = totalMinutos % 60;

      const partes = [];
      if (horasInteiras > 0)
        partes.push(`${horasInteiras} hora${horasInteiras > 1 ? 's' : ''}`);
      if (minutos > 0)
        partes.push(`${minutos} minuto${minutos > 1 ? 's' : ''}`);

      return partes.join(' e ') || '0 minutos';
    },
    listarRegistros: async function () {
      const parametros = { SetorId: this.setorId };

      this.$store.dispatch('Layout/iniciarCarregamento');
      KanbanProgramaManualmenteService.listar(parametros)
        .then((res) => {
          this.loading = true;
          this.colunas = new KanbanProgramacaoModel({
            colunas: res.data.colunas,
          });
          this.dados = this.colunas.request;
          this.loading = false;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    redireciona(tipoColuna) {
      this.$router.push({
        name: 'kanban-tipo-instrumento',
        params: { setorId: this.setorId },
        query: {
          tipoColuna: tipoColuna,
          setor: this.setor,
        },
      });
    },

    manipularInicioArrastarCard(lane, dragResults) {
      const { payload, isSource } = dragResults;
      if (isSource) {
        this.draggingCard = {
          index: payload.index,
          lane,
          cardData: {
            ...this.dados.cards[lane].cartoes[payload.index],
          },
        };
      }
    },
    manipularSoltarCard(lane, dropResult) {
      const { removedIndex, addedIndex } = dropResult;

      if (lane === this.draggingCard.lane && removedIndex === addedIndex)
        return;

      if (removedIndex !== null) {
        this.dados.cards[lane].cartoes.splice(removedIndex, 1);
        this.forcarAtualizacao();
      }
      if (addedIndex !== null) {
        this.dados.cards[lane].cartoes.splice(
          addedIndex,
          0,
          this.draggingCard.cardData
        );

        this.salvarCartaoEmNovaColuna(lane);
        this.forcarAtualizacao();
      }
    },
    obterChildPayload(index) {
      return { index };
    },
    salvarCartaoEmNovaColuna(lane) {
      const informacoesCardMovido = {
        ordemServicoId: this.draggingCard.cardData.ordemServicoId,
        tipoColunaAtual: this.draggingCard.lane,
        setorId: this.setorId,
        tipoColunaDestino: lane,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');

      KanbanProgramaManualmenteService.salvarCartaoEmNovaColuna(
        informacoesCardMovido
      )
        .then(() => {
          this.listarRegistros();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    forcarAtualizacao() {
      this.$forceUpdate();
    },
    atualizaSetor: function ({ value, text }) {
      this.setorId = value;
      this.setor = text;
    },
    atualizarMostrarLabel() {
      this.mostrarLabelResponsivo = window.innerWidth <= 700;
    },
    dataFormatada(data) {
      if (!data) return '-';
      if (data == '0001-01-01T00:00:00') return '';
      return helpers.formatarDataBr(data);
    },
  },
};
</script>

<style>
.board {
  display: flex;
  margin: 1.2rem 0.8rem;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  border-radius: 16px;
}
.coluna-nome {
  color: #344054;
  font-family: 'Work Sans', sans-serif !important;
}
.colunas:hover {
  background-color: #b5cdf5;
  transition: all 0.3s ease-in-out;
}
.lane {
  background-color: rgb(237, 236, 236);
  min-width: 23rem;
  width: 23rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(33, 33, 33, 33, 0.1);
  margin: 0 0.8rem 1.6rem 0.8rem;
  padding-bottom: 4rem;
}
.detalhe-container {
  display: flex;
  flex-direction: column;
  margin: 8px;
  gap: 8px;
}
.kanban-header {
  background-color: #c9daf8;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 16px;

  span {
    max-width: 200px;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    opacity: 0.9;
  }
}
.coluna-left {
  display: flex;
  flex-direction: column;
  height: 235px !important;
  min-width: 15rem;
  width: 15rem;
}

.coluna-left span {
  margin-top: 4px;
  text-align: left;
  padding: 4px;
}

.coluna-left-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 80px;
  height: 200px;
}
.lane-title {
  padding: 0.8rem 0.5rem;
  margin-bottom: 0.6rem;
}

.lane-title:nth-child(1) {
  text-align: center;
  padding: 4rem;
  background-color: #cce2ff;
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(33, 33, 33, 33, 0.1);
}

.placeholder {
  background-color: rgba(144, 142, 142, 0.2);
  border-radius: 0.2rem;
  transform: scaleY(0.85);
  transform-origin: 0% 0%;
}

@media screen and (max-width: 700px) {
  .board {
    flex-direction: column;
    gap: 12px;
    margin: 0;
  }
  .lane {
    margin: 0;
    margin-right: 8px;
    width: 100%;
  }
  .card {
    padding-left: 4px;
  }

  .coluna-left {
    width: 100%;
  }
  .coluna-left {
    display: none;
  }
}
</style>
