import { render, staticRenderFns } from "./Mensagem.vue?vue&type=template&id=77a1db56&scoped=true&"
import script from "./Mensagem.vue?vue&type=script&lang=js&"
export * from "./Mensagem.vue?vue&type=script&lang=js&"
import style0 from "./Mensagem.vue?vue&type=style&index=0&id=77a1db56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a1db56",
  null
  
)

export default component.exports